import APIService from "../APIService";

export default class ContaService {  
  api = new APIService();

  async all(options) {
    try {
      const response = await this.api.send("get", "conta" + (options ? (this.api.serverSideOptionsFormat(options) == "" ? "?" : this.api.serverSideOptionsFormat(options) + "&") : "?"));
      return response;
    }
    catch (e) {
      return false;
    }
  }

  async todos() {
    try {
      const response = await this.api.send("get", "conta/todos");
      return response;
    }
    catch (e) {
      return false;
    }
  }
  
  async get(data) {
    try {
      const response = await this.api.send("get", "conta/" + data.CODCTA, data);
      return response;
    }
    catch (e) {
      return false;
    }
  }

  async getByCodBanCodAge(CODBAN, CODAGE) {
    try {
      const response = await this.api.send("get", "conta/" + CODBAN + "/banco/" + CODAGE + "/agencia");
      return response;
    }
    catch (e) {
      return false;
    }
  }
  
  async store(data) {
    try {
      const response = await this.api.send("post", "conta", data);
      return response;
    }
    catch (e) {
      return false;
    }
  }
  
  async update(data) {
    try {
      const response = await this.api.send("put", "conta/" + data.CODCTA, data);
      return response;
    }
    catch (e) {
      return false;
    }
  }

  async cert(id, file) {
    try {
      let formData = new FormData();
      if (file) {
        formData.append("file", file);
      }  

      const response = await this.api.send("post", "conta-api/cert/" + id, formData);
      return response;
    }
    catch (e) {
      console.log(e)
      return false;
    }
  }
  
  async key(id, file) {
    try {
      let formData = new FormData();
      if (file) {
        formData.append("file", file);
      }  

      const response = await this.api.send("post", "conta-api/key/" + id, formData);
      return response;
    }
    catch (e) {
      return false;
    }
  }

  async removeCert(id) {
    try {
      const response = await this.api.send("post", "conta-api/remove-cert/" + id);
      return response;
    }
    catch (e) {
      return false;
    }
  }
  
  async removeKey(id) {
    try {
      const response = await this.api.send("post", "conta-api/remove-key/" + id);
      return response;
    }
    catch (e) {
      return false;
    }
  }
  
  async delete(data) {
    try {
      const response = await this.api.send("delete", "conta/" + data.CODCTA, data);
      return response;
    }
    catch (e) {
      return false;
    }
  }
}