<script setup>
import { ref, onMounted, onUpdated, defineProps, watch } from 'vue';
import Service from '../../../service/financeiro/ContaService';

const props = defineProps(["selected", "classes", 'disabled', 'codban', 'codage']);
const service = new Service();

const CodBan = ref();
const CodAge = ref();
const objeto = ref();
const lista = ref([]);
const disabled = ref(props.disabled);
const loading = ref(false);

const load = (filtro) => {
  let options = {query: {codcta: "", codban: CodBan.value.value, codage: CodAge.value.value}}

  if(filtro){
    options.query.codcta = filtro;
  }

  service.all(options)
  .then(response => {
    lista.value = [];
    const data = [];

    if(response.data){
      response.data.map(
        (entry) => data.push({label : entry.CODCTA, value : entry.CODCTA, codban: entry.CODBAN, codage: entry.CODAGE})
      );
    }

    lista.value = data;
  })
  .finally(() => {
    loading.value = false
  });
}

const filtering = (evt) => {
  loading.value = true;

  load(evt.value)
}

onMounted(() => {
  objeto.value = props.selected;
  if (props.disabled != undefined) {
    disabled.value = props.disabled;
  }
  if (props.codban != undefined) {
    CodBan.value = props.codban;
  }
  if (props.codage != undefined) {
    CodAge.value = props.codage;
  }
});

onUpdated(() => {
  if(props.selected){
    objeto.value = props.selected;
  }else{
    objeto.value = null;
  }

  if (props.disabled != undefined) {
    disabled.value = props.disabled;
  }

  if (props.codban != undefined) {
    CodBan.value = props.codban;
  }

  if (props.codage != undefined) {
    CodAge.value = props.codage;
  }
});

watch(CodAge,(newValue, oldValue)=>{
  if(newValue != oldValue){
    load("");
  }
});
</script>

<template>
  <Dropdown
    :class="classes"
    class="flex"
    v-model="objeto"
    :options="lista"
    optionLabel="label"
    placeholder="Selecione"
    :filter="true"
    :showClear="true"
    :loading="loading"
    :disabled="disabled"
    @filter="filtering"
    resetFilterOnHide
    emptyFilterMessage="Nenhum registro encontrado."
    emptyMessage="Digite para pesquisar."
  >
    <template #value="slotProps">
      <div v-if="slotProps.value">
        <span :class="'product-badge status-' +slotProps.value">{{ slotProps.value.label }}</span>
      </div>
      <span v-else>
        {{slotProps.placeholder}}
      </span>
    </template>
  </Dropdown>
</template>