  <script setup>
  import { ref, onMounted, onUpdated, defineProps, defineEmits, onBeforeMount } from 'vue';
  import moment from 'moment';
  import Toast from "primevue/toast";
  import { useToast } from "primevue/usetoast";

  const toast = useToast();

  const props = defineProps(
    [
      "classes",
      "format",
      "disabled",
      "selected",
      "idInput",
      "nameInput"
    ]
  );

  const emit = defineEmits(['update:modelValue']);
  const objeto = ref();
  const disabled = ref(props.disabled);
  const format = ref(props.format);
  const mask = ref("99/99/9999");
  const classes = ref(props.classes);

  function retorna(value) {
    emit('update:modelValue', value)
  }

  function formata(value) {
    if (value.toString().includes("/")) {
      value = value.split("/").reverse().join("-");
    }
    // console.log("value", value);
    // console.log("moment(value).format('YYYY')", moment(value).format("YYYY"));
    // console.log(format.value);
    let retorno;

    switch (format.value) {
      case "A":
        // mask.value = "9999";
        retorno = moment(value).format("YYYY");
        break;
      case "M":
        // mask.value = "99";
        retorno = moment(value).format("MM");
        break;
      case "D":
        // mask.value = "99";
        retorno = moment(value).format("DD");
        break;
      case "DM":
        // mask.value = "99/99";
        retorno = moment(value).format("DD/MM");
        break;
      case "MA":
        // mask.value = "99/9999"
        retorno = moment(value).format("MM/YYYY");
        break;
      case "Ma":
        // mask.value = "99/99";
        retorno = moment(value).format("MM/YY");
        break;
      case "DMa":
        // mask.value = "99/99";
        retorno = moment(value).format("DD/MM/YY");
        break;
      default:
        // mask.value = "99/99/9999"
        retorno = moment(value).format("DD/MM/YYYY");
        break;
    }
    // console.log(retorno);

    return retorno;
  }
  onBeforeMount(() => {
    if (props.format && props.format != undefined) {
      format.value = props.format;
    }

    switch (format.value) {
      case "A":
        mask.value = "9999";
        break;
      case "M":
        mask.value = "99";
        break;
      case "D":
        mask.value = "99";
        break;
      case "DM":
        mask.value = "99/99";
        break;
      case "MA":
        mask.value = "99/9999"
        break;
      case "Ma":
        mask.value = "99/99";
        break;
      case "DMa":
        mask.value = "99/99/99";
        break;
      default:
        mask.value = "99/99/9999"
        break;
    }
  })

  onMounted(() => {
    if (props.selected != undefined) {
      objeto.value = props.selected.replaceAll("/", "");
    }
  })

  onUpdated(() => {
    if (props.selected) {
      objeto.value = props.selected;
    } else {
      objeto.value = "";
    }

    if (props.disabled) {
      disabled.value = props.disabled;
    }

    if (props.classes) {
      classes.value = props.classes;
    }
  });

  function valida(params) {
    let aux = params.target.value.split("/")

    if (format.value == null || format.value == "DMa" || format.value == "DM") {
      if (aux[0] > 31 || aux[1] > 12) {
        toast.add({
          severity: "warn",
          summary: "Atenção",
          detail: "Data inválida",
          life: 3000
        });
      }
    } else if (format.value == "MA" || format.value == "Ma") {
      if (aux[0] > 12) {
        toast.add({
          severity: "warn",
          summary: "Atenção",
          detail: "Data inválida",
          life: 3000
        });
      }
    } else if (format.value == "M") {
      if (aux[0] > 12) {
        toast.add({
          severity: "warn",
          summary: "Atenção",
          detail: "Data inválida",
          life: 3000
        });
      }
    } else if (format.value == "D") {
      if (aux[0] > 31) {
        toast.add({
          severity: "warn",
          summary: "Atenção",
          detail: "Data inválida",
          life: 3000
        });
      }
    }
  }
  </script>

  <template>
  <div>
    <Toast />
    <span class="p-input-icon-right">
      <i class="pi pi-calendar" />
      <InputMask
        :id="idInput"
        :name="nameInput"
        :mask="mask"
        v-model="objeto"
        :disabled="disabled"
        :class="classes"
        @blur="valida"
        @update:modelValue="retorna($event)"
        placeholder="Escreva" />
    </span>
  </div>
  </template>
