<template>
  <InputText
    :value="formattedValue"
    @input="handleInput"
    @blur="formatValue"
  />
</template>

<script setup>
import { ref, computed, watch, defineProps, defineEmits } from 'vue'

// Props
const props = defineProps({
  modelValue: {
    type: Number,
    required: true,
  },
  minValue: {
    type: Number,
    default: 0,
  },
  maxValue: {
    type: Number,
    default: Infinity,
  },
})

// Emit event
const emit = defineEmits(['update:modelValue'])

// Local state
const inputValue = ref('')

const formattedValue = computed(() => {
  // return formatCurrency(inputValue.value)
  return inputValue.value
});

const handleInput = (event) => {
  let value = event.target.value != "" ? event.target.value : "0";
  console.log("📍 -> handleInput -> value:", value);
  if(value.indexOf("$") !== -1){
    // retornaValor(value);
    value = retornaValor(value);
  }
  let formatado = convertToDecimal(parseInt(value.replace(".", "")));
  event.target.value = formatado;
  // retornaValor(new Intl.NumberFormat('pr-BR', { style: 'currency', currency: 'BRL' }).format(formatado));
  event.target.value = new Intl.NumberFormat('pr-BR', { style: 'currency', currency: 'BRL' }).format(formatado);
  console.log("📍 -> handleInput -> formatado:", formatado);
  console.log("📍 -> handleInput -> formatado:", new Intl.NumberFormat('pr-BR', { style: 'currency', currency: 'BRL' }).format(formatado));
};

function retornaValor(val) {
  val = val.split("$");
  val = val[1].trim().replace(".", "").replace(",", ".");
  // console.log("val", val);
  return val;
}

function convertToDecimal(number) {
  let numStr = number.toString();

  // Se o número tem apenas um dígito, adiciona um zero à esquerda
  if (numStr.length < 3) {
      numStr = numStr.padStart(3,"0");
  }

  const regex = /(\d+)(\d{2})$/;
  return numStr.replace(regex, '$1.$2');
}


</script>
