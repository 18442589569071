<script setup>
import { ref, onMounted, onUpdated, defineProps, defineEmits, watch } from 'vue';

import VirtualScroller from 'primevue/virtualscroller';
import OverlayPanel from 'primevue/overlaypanel';


import EntidadeService from '@/service/EntidadeService';

const props = defineProps(
  [
    "classes",
    "multiple",
    "modelValue",
    'update:modelValue',
    'label',
    'disabled',
    'selected',
    'codemp',
    'simple'
  ]
);
const emit = defineEmits(['update:modelValue']);

const service = new EntidadeService();

const entidades = ref([]);
const entidade = ref(props.selected ?? null);
const label = ref(props.label ?? 'NOMENT');
const codemp = ref(props.codemp ?? null);
const loading = ref(false);
const disabled = ref(props.disabled);

const filtering = (evt) => {
  load(evt.value);
}

const load = async (term) => {
  entidades.value = [];

  loading.value = true;
  if(term && entidade.value){
    entidade.value.label = "Carregando...";
  }
  service.api.abort();

  let opts = {
    ce: codemp.value,
    filtro: term
  };

  await new Promise((resolve) => {
    service.searchEntidadeBox(opts)
    .then(response => {
      if (Array.isArray(response)) {
        const data = [];

        response.map(
          (entry) =>
          {
            if (!checks[entry.CODENT]) {
              checks[entry.CODENT] = false;
            }

            data.push(
              {
                label : entry[label.value] + " (" + entry.CODENT + ")" ?? entry.NOMENT + " (" + entry.CODENT + ")",
                value : entry.CODENT,
                doc : entry.DOCENT,
                nom_res : entry.NOMRESENT
              }
            )
          }
        );

        entidades.value = data;
      }
    })
    .finally(() => {
      loading.value = false;
      resolve();
    });
  })
}

watch(codemp, (novoValor, velhoValor) => {
  if(velhoValor != novoValor){
    entidades.value = [];
  }
})

onUpdated(async () => {
  if (props.label) {
    label.value = props.label;
  }

  if (props.disabled) {
    disabled.value = props.disabled;
  }

  if (props.codemp) {
    codemp.value = props.codemp;
  }

  if (props.selected == null) {
    entidade.value = null;
  }
});

onMounted(() => {
  if (props.simple) {
    service.allResumido()
    .then(
      response => {
        const data = [];

        response.map(
          (entry) =>
          {
            if (!checks[entry.CODENT]) {
              checks[entry.CODENT] = false;
            }

            data.push(
              {
                label : entry[label.value] ?? entry.NOMENT,
                value : entry.CODENT,
                doc : entry.DOCENT,
                nom_res : entry.NOMRESENT
              }
            )
          }
        );

        entidades.value = data;
      }
    );
    return;
  }
  mounting();
})

const mounting = async () => {
  if(props.selected){
    if(props.selected.value){
      //Carrega os dados de acordo com o codigo do contador
      await load(props.selected.value);
      // Busca o contador na lista de acordo com o código
      let selecionado = entidades.value.find(item => item.value == props.selected.value);
      let ent = {};
      // Caso ache um contador preenche os dados
      if(selecionado){
        for (const key in selecionado) {
          ent[key] = selecionado[key]
        }
        entidade.value = ent;
      }
    }
  }
}

/// multi-select
const input = ref();
const inputSelect = ref()
const overlayPanel = ref();
const entidadesSelected = ref([]);
const checks = ref([]);

const openOverlay = (ev) => overlayPanel.value.show(ev);

const onSelected = (item) => {
  let textSelected = [];

  const _ = entidadesSelected.value;

  _.push(item);

  entidadesSelected.value = _;

  entidadesSelected.value.map(
    (entry, key) => {
      textSelected.push(entry.value + " - " + entry.label)
    }
  );

  inputSelect.value = textSelected

  entidade.value = entidadesSelected.value;
  emit('update:modelValue', entidadesSelected.value)
}

const onRemove = (ev) => {
  const index = ev.value[0].split(" - ")[0];

  const _ = entidadesSelected.value;

  let i;

  _.map(
    (entry, key) => {
      if (entry.value == index) {
        i = key;
      }
    }
  )

  _.splice(i, 1);

  entidadesSelected.value = _;

  const __ = checks.value;

  delete __[index];

  checks.value = __;

  entidade.value = entidadesSelected.value;

  emit('update:modelValue', entidadesSelected.value)
}
</script>

<template>
  <div v-if="props.multiple">
    <Chips v-model="inputSelect" separator="," @click="openOverlay" @remove="onRemove" />

    <OverlayPanel ref="overlayPanel" style="width: 600px;" >
      <div class="card flex flex-wrap justify-content-center gap-3">
        <span class="p-input-icon-left" style="width : 100%" >
            <i class="pi pi-search" />
            <InputText placeholder="Procurar" v-model="input"  style="width : 100%" @input="(ev) => load(ev.target.value)" />
        </span>
      </div>
      <div class="justify-content-center" v-if="loading">
        <ProgressBar mode="indeterminate" style="height: 6px"></ProgressBar>
      </div>
      <VirtualScroller :items="entidades" :itemSize="entidades.length" style="width: 100%; height: 200px" v-else-if="entidades">
        <template v-slot:item="{ item, options }">
          <div :class="['flex align-items-center p-2', { 'surface-hover': options.odd }]" style="height: 50px">
            <div class="field col-1">
              <Checkbox v-model="checks[item.value]" :binary="true" @input="onSelected(item)" />
            </div>
            <div class="field col-9">{{ item.label }} ({{ item.value }})</div>
          </div>
        </template>
      </VirtualScroller>
    </OverlayPanel>
  </div>
  <Dropdown
    v-model="entidade"
    :disabled="disabled"
    :class="classes"
    :options="entidades"
    :filter="true"
    :showClear="true"
    :loading="loading"
    :filterFields="['label','value', 'doc', 'nom_res']"
    class="flex"
    resetFilterOnHide
    optionLabel="label"
    placeholder="Selecione"
    emptyFilterMessage="Nenhum registro encontrado"
    emptyMessage="Nenhuma opção disponível"
    @filter="filtering"
    @change="(event) => emit('update:modelValue', event.value)"
    v-else
  >
    <template #value="slotProps">
      <div v-if="slotProps.value">
        <span :class="'product-badge status-' +slotProps.value">{{slotProps.value.label}}</span>
      </div>
      <span v-else>
        {{slotProps.placeholder}}
      </span>
    </template>
  </Dropdown>
</template>