<script setup>
import { ref, defineProps } from 'vue';

const props = defineProps(['texto'])

const textoLabel = ref(props?.texto);

</script>
<template>
  <div>
    <small class="p-error">
      <Translate :text="textoLabel ? textoLabel : 'Este campo é obrigatório'" />
    </small>
  </div>
</template>
