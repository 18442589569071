import APIService from "./APIService";
import axios from "axios";

export default class EntidadeService {
  api = new APIService();

  async all(query) {
    try {
      return await this.api.send("get", `entidade?${query}`);
    }
    catch (e) {
      return false;
    }
  }

  async allResumido(codemp) {
    try {

      const apiUrl = "entidade/allResumido";

      const data = {
        CODEMP: codemp
      };

      const response = await this.api.send("get", apiUrl, data);
      return response;
    }
    catch (e) {
      return false;
    }
  }
  
  async allResumido2(codemp) {
    try {

      const apiUrl = "entidade/allResumido2";

      const data = {
        CODEMP: codemp
      };

      const response = await this.api.send("post", apiUrl, data);
      return response;
    }
    catch (e) {
      return false;
    }
  }

  async search(src) {
  // async searchEntidadeBox(src) {
    try {
      const response = await this.api.send("get", "entidade/search/" + src);
      return response;
    }
    catch (e) {
      return false;
    }
  }

  async searchEntidadeBox(src) {
  // async search(src) {
    try {
      // console.log("src",src);
      // const response = await this.api.send("get", "entidade/search/" + src);
      const response = await this.api.send("get", "entidade/eb" + `?filtro=${src.filtro}${src.ce != null ?"&ce=" + src.ce : ''}`);
      // console.log("response",response);
      return response;
    }
    catch (e) {
      return false;
    }
  }

  async searchCidadeBox(src) {

      try {

        const response = await this.api.send("get", "entidade/cidade/eb" + `?filtro=${src.filtro}${src.ce != null ?"&ce=" + src.ce : ''}`);
        return response;
      }
      catch (e) {
        return false;
      }
    }
  

  async procura( options, entidadeSrc, telefoneSrc = null, codigoSrc = null) {
    try {
      const response = await this.api.send("get", "entidade/procura" + (options ? (this.api.serverSideOptionsFormat(options) == "" ? "?" : this.api.serverSideOptionsFormat(options)  + "&") : "?")  + "entidade=" + entidadeSrc + "&telefone=" + telefoneSrc + "&codigo=" + codigoSrc);

      return response;
    }
    catch (e) {
      return false;
    }
  }

  async procuraFuncionario() {

    try {
      const response = await this.api.send("get", "entidade/procuraFuncionario");

      return response;
    }
    catch (e) {
      return false;
    }
  }

  
  async searchRescisao(data) {

    try {
      const response = await this.api.send("post", "entidade/rescisoes", data);

      return response;
    }
    catch (e) {
      return false;
    }
  }

  async procuraByMudanca(entidadeSrc, telefoneSrc, anoInicial, anoFinal, options) {
    try {
      // const response = await this.api.send("get", "entidade/procuraByMudanca/" + entidadeSrc + "/" + telefoneSrc + "/" + anoInicial + "/" + anoFinal + (options ? (this.api.serverSideOptionsFormat(options) == "" ? "?" : this.api.serverSideOptionsFormat(options)  + "&") : "?"));
      const response = await this.api.send("get", "entidade/procuraByMudanca/" + entidadeSrc + "/" + telefoneSrc + "/" + anoInicial + "/" + anoFinal + "?query=" + JSON.stringify(options.query));

      return response;
    }
    catch (e) {
      return false;
    }
  }

  async get(CodEnt, query) {
    try {
      const response = await this.api.send("get", "entidade/" + CodEnt + (query ? "?" + query : ""));
      return response;
    }
    catch (e) {
      return false;
    }
  }

  async store(data) {
    try {
      const response = await this.api.send("post", "entidade", data);
      return response;
    }
    catch (e) {
      return false;
    }
  }

  async update(data) {
    try {
      var response = null;
      if (data.CODENT) {
        response = await this.api.send("put", "entidade/" + data.CODENT, data);
      }
      else {
        response = await this.api.send("post", "entidade", data);
      }

      return response;
    }
    catch (e) {
      return false;
    }
  }

  async delete(CodEnt) {
    try {
      const response = await this.api.send("delete", "entidade/" + CodEnt);
      return response;
    }
    catch (e) {
      return false;
    }
  }
}