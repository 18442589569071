<!-- src/components/NumberInput.vue -->
<template>
  <div>
    <InputText
      :value="value"
      @input="onInput"
      @keypress="onKeyPress"
      :maxlength="maxLength"
    />
  </div>
</template>

<script setup>
import { toRefs, defineEmits, defineProps, onMounted } from 'vue';
import InputText from 'primevue/inputtext';

// Define props
const props = defineProps({
  value: {
    type: String,
    default: ''
  },
  maxLength: {
    type: Number,
    required: true
  }
});

// Define emits
const emit = defineEmits(['update:modelValue']);

// Destructure props to use in the template
const { value, maxLength } = toRefs(props);

// Define methods for input validation
const onInput = (event) => {
  const newValue = event.target.value.replace(/\D/g, '');
  if (newValue.length <= maxLength.value) {
    emit('update:modelValue', newValue != "" ? parseInt(newValue) : 0);
  }
};

const onKeyPress = (event) => {
  if (!/\d/.test(event.key)) {
    event.preventDefault();
  }
};

onMounted(()=>{
  console.log(value.value);
});
</script>

<style scoped>
input {
  width: 100%;
  padding: 8px;
  font-size: 16px;
}
</style>
