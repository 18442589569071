<script setup>
import { ref, onMounted, onUpdated, defineProps, defineEmits, onBeforeMount } from 'vue';

const props = defineProps(
  [
    "classes",
    "format",
    "disabled",
    "selected",
    "idInput",
    "nameInput"
  ]
);

const emit = defineEmits(['update:modelValue']);
const objeto = ref();
const disabled = ref(props.disabled);
const format = ref(props.format);
const classes = ref(props.classes);
const mask = ref("999.999.999-99");

function retorna(value) {
  emit('update:modelValue', value)
}

onBeforeMount(() => {
  if (props.format && props.format != undefined) {
    format.value = props.format;
  }
  
  switch (format.value) {
    case "CPF":
      mask.value = "999.999.999-99";
      break;
    case "CNPJ":
      mask.value = "99.999.999/9999-99";
      break;
    case "CEL":
      mask.value = "(99) 99999-9999";
      break;
    case "TEL":
      mask.value = "(99) 9999-999";
      break;
    default:
      mask.value = "999.999.999-99"
      break;
    }
})

onMounted(() => {
  if (props.selected != undefined) {
    objeto.value = props.selected;
  }
})

onUpdated(() => {
  if (props.selected) {
    objeto.value = props.selected;
  } else {
    objeto.value = "";
  }

  if (props.disabled) {
    disabled.value = props.disabled;
  }

  if (props.classes) {
    classes.value = props.classes;
  }
});
</script>

<template>
<div>
  <InputMask
    :id="idInput"
    :name="nameInput"
    :mask="mask"
    v-model="objeto"
    :disabled="disabled"
    :class="classes"
    @update:modelValue="retorna($event)"
    placeholder="Escreva" />
</div>
</template>